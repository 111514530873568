import React, { useEffect, useState, useRef, useCallback } from 'react'
import Lottie from 'react-lottie';
import bg from '../../../assests/bg.jpg'
import bg1 from '../../../assests/bg1.jpg'
import bg2 from '../../../assests/bg2.jpg'
import bg3 from '../../../assests/bg3.jpg'
import bg4 from '../../../assests/bg4.jpg'
import firstFlow from '../../../assests/firstFlow.json'
import firstWhiteFlow from '../../../assests/white.json'
import SecondFlow from '../../../assests/blue&RedSecondFlow.json'
import Dignoal from '../../../assests/OrangeSecondFlow.json'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Particles from '../particles';
import { useSelector } from 'react-redux';
import './mainsection.css'


function MainSection() {
  const { t } = useTranslation();
  const nightMode = useSelector((state) => state.NightMode);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [particlesKey, setParticlesKey] = useState(1);
  const images = [
    bg1,
    bg2,
    bg3,
    bg4,
  ];
  const firstFlowOptions = {
    loop: true,
    autoplay: true,
    animationData: firstWhiteFlow,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const SecondFlowOptions = {
    loop: true,
    autoplay: true,
    animationData: Dignoal,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  useEffect(() => {
    // Whenever nightMode changes, update the key to trigger a re-render of Particles
    setParticlesKey((prevKey) => prevKey + 1);
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      ); // Change image index
    }, 7000);

    return () => clearInterval(interval);
  }, [nightMode]);
  return (
    <>
        {/* <Particles  key={particlesKey}/> */}
        <main className={`main-section`}>
          <div
            style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
            className={nightMode?"night-main-background-section":'day-main-background-section'}
          ></div>
            <Container className='main-container'>
                <Row>
                    <Col lg={12} sm={12}>
                        <h1 className={`main-heading`}>
                            {t("mainheading")}
                          </h1>
                        {/* <Lottie options={firstFlowOptions} height='100px' width='600px' /> */}
                        <Lottie options={SecondFlowOptions} height='100px' width='600px' />
                        <h2 className={`main-subheading`}>
                          {t("mainsubheading")}
                        </h2>
                    </Col>   
                                     
                </Row>
            </Container>
        </main>
    </>
  )
}

export default MainSection