import React from 'react'
import logo from "../../../assests/logo.png";
import darklogo from "../../../assests/darklogo.png";
import { useSelector } from 'react-redux';

function Logo({logotype}) {
  const nightMode = useSelector(state => state.dayNightMode);
  const logoToUse = logotype === 'dark' ? darklogo : logo;
  return (
      <a href='/'>
        {nightMode?
          <img src={darklogo} alt='BringIt Go' width={35}/>
          :
          <img src={logoToUse} alt='BringIt Go' width={35}/>
        }
      </a>
  )
}

export default Logo