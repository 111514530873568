import React, {useState, useEffect} from 'react'
import { Container, NavDropdown, Navbar, Offcanvas, Nav, } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Logo from '../logo'
import PortfolioIcon from '../../../assests/portfolioIcon.png'
import AboutUsIcon from '../../../assests/aboutUsIcon.png'
import PriceIcon from '../../../assests/priceIcon.png'
import ContactIcon from '../../../assests/contactIcon.png'
import NightIcon from '../../../assests/DarkSwitch.png'
import DayIcon from '../../../assests/DaySwitch.png'
import { useDispatch, useSelector } from 'react-redux';
import { toggleDayNightMode } from '../../../redux/action';
// import CareerIcon from '../../../assests/careerIcon.png'
// import TestimonialIcon from '../../../assests/testimonial.png'
import './header.css'

function Header() {
  const dispatch = useDispatch();
  let nightMode = useSelector(state => state.NightMode);
  const [scrolled, setScrolled] = useState(false);
  // nightMode=localStorage.getItem('mode');
  
  const { t,i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const handleToggleDayNightMode = () => {
    dispatch(toggleDayNightMode());
  };
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <header className={scrolled ? 'scrolled' : ''}>
        {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand}>
          <Container>
            <Navbar.Brand href="/">
                <Logo/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand} ${nightMode?"night-text-color":"day-text-color"}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand} ${nightMode?"night-text-color":"day-text-color"}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton className={nightMode?"night-bg night-text-color":"day-bg day-text-color"}>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  What are you looking for
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav variant="underline" defaultActiveKey="/" className="justify-content-end flex-grow-1 pe-3 d-flex">
                  <NavDropdown
                    className="text-light"
                    title={t('services')}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="/blockchain">
                        {t('blockchain')}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/cloud">
                        {t('cloudservices')}
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item href="/unity">
                        Unity Development
                    </NavDropdown.Item> */}
                    {/* <NavDropdown.Item href="/artificial-intelligence">
                        Artificial Intelligence
                    </NavDropdown.Item> */}
                    {/* <NavDropdown.Item href="/mechanical-design">
                        Mechanical Designing
                    </NavDropdown.Item> */}
                    <NavDropdown.Item href="/mobile-development">
                        {t('mobiledevelopment')}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/web">
                        {t('web')}

                    </NavDropdown.Item>
                    <NavDropdown.Item href="/designing">
                        {t('uxui')}
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/portfolio" className={`d-flex me-1 align-items-center`}>
                        <strong>
                          {t('portfolio')}
                        </strong>
                  </Nav.Link>
                  <Nav.Link href="/about-us" className={`d-flex me-1 align-items-center`}>
                        <strong>
                          {t('aboutus')}
                        </strong>
                  </Nav.Link>
                  <Nav.Link href="/pricing" className={`d-flex me-1 align-items-center`}>
                        <strong>
                        {t('price')}
                        </strong>
                  </Nav.Link>
                  {/* <Nav.Link href="/#meeting" className={`d-flex me-1 align-items-center`}>
                        <strong>
                          {t('getintouch')}
                        </strong>
                  </Nav.Link> */}
                  {/* <Nav.Link href="/career" className='me-4'>
                    <strong>{t('career')}</strong>
                  </Nav.Link> */}
                  <Nav.Link className='d-flex'>
                        <button className='lang lang-border' onClick={() => changeLanguage('en')}>EN</button>
                        <button className='lang lang-border' onClick={() => changeLanguage('de')}>DE</button>
                        <button className='lang' onClick={() => changeLanguage('fr')}>FR</button>       
                  </Nav.Link>
                  {/* {!nightMode?
                    <>
                      <Nav.Link className='d-flex align-items-center night-mode' onClick={handleToggleDayNightMode}>
                        <img className='' width={40} src={NightIcon} alt='Day Mode'/>&nbsp;Light             
                      </Nav.Link>
                    </>
                    :
                    <>
                      <Nav.Link className='d-flex align-items-center day-mode night-text-color' onClick={handleToggleDayNightMode}>
                        Dark&nbsp;
                        <img className='' width={40} src={DayIcon} alt='Night Mode'/>               
                      </Nav.Link>
                    </>
                  } */}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </header>
  )
}

export default Header