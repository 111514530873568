import React from 'react'
import Main from '../../component/layout/mainSection';
import Services from '../../component/layout/services';
import Projects from '../../component/layout/projects';
import Clients from '../../component/layout/client';
import Footer from '../../component/common/footer';
import Meeting from '../../component/layout/meeting';

function Home() {
  const style={
    backgroundColor:'#171818' ,
    paddingTop:'100px'
  }
  return (
    <>
      <Main/>
      <div style={style}>
          <Services/>
          <Clients/>
          {/* <Projects/> */}
          <Meeting/>
      </div>
          <Footer/>
    </>
  )
}

export default Home