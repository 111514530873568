import React from 'react'
import Logo from "../logo/index"
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons';
import "./footer.css"

function Footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <div className='footer-section text-light bg-dark'>
          <Row>
            <Col md={6}>
              <Logo logotype="dark"/>
              <p className='mt-3 text-secondary'>Bringit Go.<br/>All Rights Reserved.</p>
            </Col>
            <Col className='d-flex justify-content-center'>
              <div>
                <h4>Let Us Know</h4>
                <div className='d-flex justify-content-center'>
                    <div className='d-block'>
                      <div className='mt-2'>
                        <a className='footerlinks' href='mailto:info@bringitgo.tech?subject='>info@bringitgo.tech</a>
                      </div>
                      <div className='mt-2'>
                        <a className='footerlinks' href='tel:+447413402742'>+44-7413402742</a>
                      </div>
                    </div>
                </div>
              </div>
            </Col>
            <Col className='d-flex justify-content-center'>
              <div>
                <h4>Social Networks</h4>
                <div className="d-flex justify-content-center">
                  <a className='footerlinks' href='https://www.facebook.com/profile.php?id=100089307017324' target='_blank'>
                    <FontAwesomeIcon icon={faFacebook} size="2x" style={{ marginRight: '10px' }} />
                  </a>
                  <a className='footerlinks' href='https://github.com/bringitgo' target='_blank'>
                    <FontAwesomeIcon icon={faGithub} size="2x" />

                  </a>
                </div>
              </div>
            </Col>
          </Row>
    </div>
  )
}

export default Footer