import React, {useEffect,useState} from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap'
import Youtubevideo from "../youtubevideo/index.jsx"
import constants from "../../../contants"
import {  useSelector } from 'react-redux';
import './projectBody.css'
const {projects} = constants

function ProjectBody() {
    let nightMode = useSelector(state => state.NightMode);
    const { id } = useParams();
    let project;
    for (let index = 0; index < projects.length; index++) {
        if(projects[index].id===id){
            project=projects[index];
            break;
        }
    }
    if(!project){
        window.location.href = '/';
    }
    return (
        <div className={`project-body ${nightMode?"night-theme":"day-theme"}`}>
            <Row className='justify-content-center'>
                <Col mx='auto'>
                    <h1 className='project-name'>{project.Name}</h1>
                    <p className='portfolio-description'>{project.Description}</p>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col className='project-solution'>
                    <img className='project-image' src={project.GIF?project.GIF:project.Image} />
                </Col>
            </Row>
        </div>
    )
}

export default ProjectBody