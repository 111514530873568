import React, { useEffect,useLayoutEffect,useRef } from 'react'
import { Card, Container, Row, Col, Image } from 'react-bootstrap'
import Website from '../../../assests/web-service-icon.svg'
import Blockchain from '../../../assests/blockchain-service-icon.png'
import Mobile from '../../../assests/mobile-servcie-icon.png'
import UIUX from '../../../assests/UI&UX-service-icon.svg'
import Cloud from '../../../assests/cloud-service-icon.png'
import Game from '../../../assests/game-service-icon.png'
import Mechanical from '../../../assests/mechanical-service-icon.png'
import Service from '../../common/service';
import { useTranslation } from 'react-i18next';
import {  useSelector } from 'react-redux';

import './services.css'

function Services() {
    const { t } = useTranslation();
    let nightMode = useSelector(state => state.NightMode);
  return (
    <div className={nightMode?"night-service-section":'day-service-section'}>
        <Row className='justify-content-center'>
            <Col>
                <h2 className='services-heading text-white'>{t("ourservices")}</h2>
            </Col>
        </Row>
        <Row>
            <Col>
                <p className='text-white px-5'>
                    {t("ourservicessubtext")}
                </p>
            </Col>
        </Row>
        <Row>
            <Col>
                <a href='/service/blockchain'>
                <Service
                    icon={Blockchain}
                    alt={'Blockchain Icon'}
                    serviceHeading={t('blockchain')}
                    serviceDetails={t("blockchainservicedescription")}
                />
                </a>
            </Col>
            <Col>
                <Service
                    icon={Cloud}
                    alt={'Cloud Icon'}
                    serviceHeading={t('cloudservices')}
                    serviceDetails={t("cloudchainservicedescription")}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <Service
                    icon={Mobile}
                    alt={'Mobile Icon'}
                    serviceHeading={t('mobiledevelopment')}
                    serviceDetails={t('mobileservicedescription')}
                />
            </Col>
            <Col>
                <Service
                    icon={Website}
                    alt={'Website Icon'}
                    serviceHeading={t('web')}
                    serviceDetails={t("webservicedescription")}
                />
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col md={6}>
                <Service
                    icon={UIUX}
                    alt={'UIUX Icon'}
                    serviceHeading={t('uxui')}
                    serviceDetails={t('uiuxservicedescription')}
                />
            </Col>
        </Row>
    </div>
  )
}

export default Services