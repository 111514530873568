import React, { useState } from 'react'
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import SuccessModal from '../../common/successModel/index';
import { useTranslation } from 'react-i18next';
import {  useSelector } from 'react-redux';
import constants from '../../../contants';
import './contact.css'
const { productionAPI } = constants;

function Contact() {
  const { t } = useTranslation();
  let nightMode = useSelector(state => state.NightMode);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formData, setFormData] = useState({
    subject: '',
    email: '',
    phoneNumber: '',
    text: '',
  });
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    // return;
    try {
      const response = await fetch(`${productionAPI}/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        handleShowSuccessModal();
        // Handle success, e.g., show a success message to the user
      } else {
        console.error('Failed to send email');
        // Handle failure, e.g., show an error message to the user
      }
    } catch (error) {
      console.error('Error sending email:', error);
      // Handle other errors, e.g., network issues
    }
  };
  return (
    <div id="contact-form" className={`${nightMode?"bg-dark night-text-color":"bg-light"} p-5 rounded`}>
        <Row>
            <Col>
                <h2 className='contact-heading text-light'>{t("contactheading")}</h2>
            </Col>
        </Row>
        <Row>
            <Col>
              <p className='contact-sub-heading'>{t("contactsubheading")}</p>
            </Col>
        </Row>
        <Row>
          <Col className='contact-form-container'>
            <Form className='contact-form' onSubmit={handleSubmit}>
              <Form.Group className="mb-3" >
                <FloatingLabel controlId="floatingInput" label={t("fullname")}>
                  <Form.Control controlId="name" name="subject" type="text" onChange={handleChange} placeholder={t("fullname")} />  
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3" >
                <FloatingLabel controlId="floatingInput" label={t("email")}>
                  <Form.Control controlId="email" name="email" type="email" onChange={handleChange} placeholder={t("email")} />     
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3" >
                <FloatingLabel controlId="floatingInput" label={t("phonenumber")}>
                  <Form.Control controlId="phoneNumber" name="phoneNumber" type="text"  onChange={handleChange} placeholder={t("phonenumber")} />
                </FloatingLabel>
              </Form.Group>
              <FloatingLabel controlId="floatingTextarea" label={t("message")}>
                <Form.Control
                  as="textarea"
                  placeholder={t("message")}
                  name='text'
                  style={{ height: '100px' }}
                  onChange={handleChange}
                />
              </FloatingLabel>
              <div className='d-flex justify-content-center'>
                <Button controlId="submitButton" className='mt-4 submit-btn' type="submit">
                  Submit
                </Button>
              </div>
            </Form>
            <SuccessModal show={showSuccessModal} handleClose={handleCloseSuccessModal} />
          </Col>
        </Row>
    </div>
  )
}

export default Contact